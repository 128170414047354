<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Dashboard</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row">
            <div class="col-md-3 col-sm-6 col-12">
              <div class="info-box">
                <span class="info-box-icon bg-info"><i class="far fa-envelope"></i></span>
  
                <div class="info-box-content">
                  <span class="info-box-text">Ticket</span>
                  <span class="info-box-number">{{t_issues}}</span>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <!-- /.col -->
            <div class="col-md-3 col-sm-6 col-12">
              <div class="info-box">
                <span class="info-box-icon bg-success"><i class="far fa-flag"></i></span>
  
                <div class="info-box-content">
                  <span class="info-box-text">Jobsheet</span>
                  <span class="info-box-number">{{t_js}}</span>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <!-- /.col -->
            <div class="col-md-3 col-sm-6 col-12">
              <div class="info-box">
                <span class="info-box-icon bg-warning"><i class="far fa-copy"></i></span>
  
                <div class="info-box-content">
                  <span class="info-box-text">Assets</span>
                  <span class="info-box-number">0</span>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <!-- /.col -->
            <div class="col-md-3 col-sm-6 col-12">
              <div class="info-box">
                <span class="info-box-icon bg-danger"><i class="far fa-user"></i></span>
  
                <div class="info-box-content">
                  <span class="info-box-text">Users</span>
                  <span class="info-box-number">0</span>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <!-- /.col -->
          </div>
        <!-- /.row -->


        <div class="row">
            <div class="col-12">
                <div class="card card-info">
                    <div class="card-header">
                        <h3 class="card-title">Latest New Ticket</h3>
        
                        <div class="card-tools" hidden>
                            <div class="input-group input-group-sm" style="width: 150px;">
                                <input type="text" name="table_search" class="form-control float-right" placeholder="Search">
        
                                <div class="input-group-append">
                                    <button type="submit" class="btn btn-default">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>Ticket</th>
                                    <th>Date</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Created By</th>
                                    <th>Assign To</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="issues !== 'No Data';else templateName">
                                <tr *ngFor="let issue of issues" >
                                    <td><b class="tex-primary">{{issue.issue_ticket}}</b></td>
                                    <td>{{change(issue.issue_created)}}</td>
                                    <td>{{issue.issue_title}}</td>
                                    <td>{{issue.issue_desc}}</td>
                                    <td>{{issue.createdby}}</td>
                                    <td>{{issue.assign}}</td>
                                    <td *ngIf="issue.issue_status == 0;else inprog"><a class="badge bg-danger">New Issue</a></td>
                                    <ng-template #inprog>
                                        <td *ngIf="issue.issue_status == 1;else inprog2"><a class="badge bg-warning">In Progress</a></td>
                                    </ng-template>
                                    <ng-template #inprog2>
                                        <td *ngIf="issue.issue_status == 2;else inprog3"><a class="badge bg-info">Completed</a></td>
                                    </ng-template>
                                    <ng-template #inprog3>
                                        <td><a class="badge bg-success">Closed</a></td>
                                    </ng-template>
                                    <td><button class="btn btn-primary" [routerLink]="['/issue-view', issue.issue_id]">View</button></td>
                                </tr>
                            </tbody>
                            <ng-template #templateName>
                                <tr><td colspan="5">No data available at this moment</td></tr>
                            </ng-template>
                        </table>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
        </div>


        <div class="row">
            <div class="col-12">
                <div class="card card-success">
                    <div class="card-header">
                        <h3 class="card-title">Latest Job Sheet</h3>
        
                        <div class="card-tools" hidden>
                            <div class="input-group input-group-sm" style="width: 150px;">
                                <input type="text" name="table_search" class="form-control float-right" placeholder="Search">
        
                                <div class="input-group-append">
                                    <button type="submit" class="btn btn-default">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body table-responsive p-0 ">
                        <table class="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                  <th>#No</th>
                                  <th>Date</th>
                                  <th>Ticket</th>
                                  <th>Workdone</th>
                                  <th>Open By</th>
                                  <th>Services</th>
                                  
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody *ngIf="jobsheets !== 'No Data' else nodata">
                                <tr *ngFor ="let jobsheet of jobsheets">
                                  <td>#{{jobsheet.js_id}}</td>
                                  <td>{{change(jobsheet.js_created)}}</td>
                                  <td><a [routerLink]="['/issue-view', jobsheet.issue_id]">{{jobsheet.issue_ticket}}</a></td>
                                  <td>{{jobsheet.js_workdone}}</td>
                                  <td>{{jobsheet.opener}}</td>
                                  <td>{{jobsheet.js_services}}</td>
                                  <!--<td *ngIf="jobsheet.js_status == 0;else aa"><span class="badge bg-danger">New jobsheet</span></td>
                                  <ng-template #aa><td *ngIf="jobsheet.js_status == 1;else bb"><span class="badge bg-warning">Read by admin</span></td></ng-template>
                                  <ng-template #bb><td><span class="badge bg-success">Acknowledged</span></td></ng-template>-->
                                  
                                  <td>
                                      <button class="btn btn-primary" (click)="viewjs(jobsheet.js_id)">View</button>
                                  </td>
                                </tr>
                              </tbody>
                              <ng-template #nodata>
                                  <tr>
                                      <td colspan="7">No data at this moment</td>
                                  </tr>
                              </ng-template>
                        </table>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>

        </div>
    </div>
    <!-- Main row -->

    
</section>
<!-- /.content -->
