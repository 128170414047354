import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-jobsheet-view',
  templateUrl: './jobsheet-view.component.html',
  styleUrls: ['./jobsheet-view.component.scss']
})
export class JobsheetViewComponent implements OnInit {
  public id: any = false
  public js: any = false
  constructor(
    private route : ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private toastr : ToastrService
  ) { }

  ngOnInit(): void {
    this.id = sessionStorage.tmp_js
    this.getjsDetails(this.id)
  }

  async getjsDetails(id: any){
    let res = await this.appService.getJSDetails(id)
    this.js =  res[0]
    console.log(this.js)
  }
}
