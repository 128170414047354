
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>User List</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active">User Profile</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
                <div class="card-title">
                  <button [routerLink]="['/register']" class="btn btn-primary elevation-1"> <i class="fas fa-plus"></i> New User</button>
                </div>
    
              <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 190px;">
                  <input type="text" name="table_search" class="form-control float-right" placeholder="Search">
    
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-default">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-2">
              <table class="table table-hover text-nowrap border">
                <thead class="card-outline card-primary">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Level</th>
                    <th>Last Login</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor ="let user of users">
                    <td>{{user.user_id}}</td>
                    <td>{{user.user_name}}</td>
                    <td>{{user.user_email}}</td>
                    <td>
                        <span *ngIf="user.user_level == 1 else notAdmin">Admin</span>
                        <ng-template #notAdmin>Technician</ng-template>
                    </td>
                    
                    <td>{{user.user_lastlogin}}</td>
                    <td>
                        <button class="btn btn-primary" [routerLink]="['/edit', user.user_id]">Edit</button>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>
</div>
</section>
