<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <img src="./assets/img/logo.png" width="80px"><br>
        <a [routerLink]="['/']" class="h4"><b>BZB ACM - INTERNAL</b></a><br>
        <a class="h6">Armanee Terrace Condominium</a>
    </div>
    <div class="card-body">
        <p class="login-box-msg">Sign in to start your session</p>

        <form [formGroup]="loginForm" (ngSubmit)="loginByPHP()">
            <div class="input-group mb-3">
                <input
                    formControlName="email"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3">
                <input
                    formControlName="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <div class="icheck-primary">
                        <input type="checkbox" id="remember" />
                        <label for="remember"> Remember Me </label>
                    </div>
                </div>
                <!-- /.col -->
                <div class="col-5">
                    <app-button
                        [type]="'submit'"
                        [block]="true"
                        [loading]="isAuthLoading"
                        [disabled]="isFacebookLoading || isGoogleLoading"
                    >
                        Sign In
                    </app-button>
                </div>
                <!-- /.col -->
            </div>
        </form>


        <p class="mb-1">
            <a [routerLink]="['/forgot-password']">I forgot my password</a>
        </p>
        <p class="mb-0" hidden>
            <a [routerLink]="['/register']" class="text-center">
                Register a new membership
            </a>
        </p>
    </div>
    <!-- /.login-card-body -->
</div>
