<app-dropdown hidden>
    <ng-container dropdown-button>
        <i class="flag-icon flag-icon-gb"></i>
    </ng-container>
    <ng-container dropdown-menu>
        <a href="#" class="dropdown-item">
            <i class="flag-icon flag-icon-gb mr-2"></i> English
        </a>

    </ng-container>
</app-dropdown>
