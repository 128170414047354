
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Jobsheet List</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active">Jobsheet list</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
                <div class="card-title">
                  <button [routerLink]="['/jobsheet-new']" class="btn btn-primary elevation-1"> <i class="fas fa-plus"></i> New Jobsheet</button>
                </div>
    
              <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 190px;">
                  <input type="text" name="table_search" class="form-control float-right" placeholder="Search">
    
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-default">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-2">
              <table class="table table-hover text-nowrap border">
                <thead class="card-outline card-primary">
                  <tr>
                    <th>#No</th>
                    <th>Date</th>
                    <th>Issue ID</th>
                    <th>Workdone</th>
                    <th>Open By</th>
                    <th>Services</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody *ngIf="jobsheets !== 'No Data' else nodata">
                  <tr *ngFor ="let jobsheet of jobsheets">
                    <td>#{{jobsheet.js_id}}</td>
                    <td>{{change(jobsheet.js_created)}}</td>
                    <td><a [routerLink]="['/issue-view', jobsheet.issue_id]">{{jobsheet.issue_ticket}}</a></td>
                    <td>{{jobsheet.js_workdone}}</td>
                    <td>{{jobsheet.opener}}</td>
                    <td>{{jobsheet.js_services}}</td>
                    <td *ngIf="jobsheet.js_status == 0;else aa"><span class="badge bg-danger">New</span></td>
                    <ng-template #aa ><td *ngIf="jobsheet.js_status == 1;else bb"><span class="badge bg-warning">In Review</span></td></ng-template>
                    <ng-template #bb ><td *ngIf="jobsheet.js_status == 2;else cc"><span class="badge bg-success">Acknowledged</span></td></ng-template>
                    <ng-template #cc ><td><span class="badge bg-secondary">Closed</span></td></ng-template>
                    <td>
                        <button class="btn btn-primary" (click)="viewjs(jobsheet.js_id)">View</button>
                    </td>
                  </tr>
                </tbody>
                <ng-template #nodata>
                    <tr>
                        <td colspan="7">No data at this moment</td>
                    </tr>
                </ng-template>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>
</div>
</section>
