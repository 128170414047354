
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>New Jobsheet</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">New Jobsheet</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        
        <div class="row">
            
            <div class="col-12">
                <div class="card card-body">
     
                    <form id="user_register" [formGroup]="registerForm" (ngSubmit)="newjobsheetByPHP()">

                        <div class="row mb-4">
                            <label for="horizontal-password-input" class="col-sm-3 col-form-label">Ticket ID</label>
                            <div class="col-sm-9" >
                            <select #f *ngIf="issues;else ndata" formControlName="issue_id" id="level" class="form-control" name="issue_id" (change)="popIssue(f.value)" required>
                                <option value="{{issue.issue_id}}" *ngFor="let issue of issues">{{issue.issue_ticket}} - {{issue.issue_title}}</option>    
                            </select>
                            <ng-template #ndata>
                                <option>No Data</option>    
                            </ng-template>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Issue Title</label>
                            <div class="col-sm-9">
                                <input formControlName="issue_title" class="form-control" name="issue_title" type="text" placeholder="eg. Pipe burst" maxlength="50" autocomplete="off" required>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <label for="horizontal-email-input" class="col-sm-3 col-form-label">Ticket Description</label>
                            <div class="col-sm-9">
                                <textarea formControlName="issue_desc" class="form-control" name="issue_desc" type="text" required></textarea>
                            </div>
                        </div>
                
                        <div class="row mb-4">
                            <label for="horizontal-email-input" class="col-sm-3 col-form-label">Location</label>
                            <div class="col-sm-9">
                                <input formControlName="issue_location" class="form-control" name="issue_location" type="text" placeholder="eg. Guard House" autocomplete="off" required>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <label for="horizontal-email-input" class="col-sm-3 col-form-label">Work done</label>
                            <div class="col-sm-9">
                                <textarea formControlName="js_workdone" class="form-control" name="js_workdone" type="text" required placeholder="eg. Replace sink to new one"></textarea>
                            </div>
                        </div>
            
                        <div class="row mb-4">
                            <label for="horizontal-password-input" class="col-sm-3 col-form-label">Services type</label>
                            <div class="col-sm-9">
                            <select formControlName="js_services" id="level" class="form-control" name="js_services" placeholder="Please Select" required>
                                <option value="{{service.name}}" *ngFor="let service of services">{{service.name}}</option>
                                         
                            </select>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <label for="horizontal-email-input" class="col-sm-3 col-form-label">Remarks</label>
                            <div class="col-sm-9">
                                <textarea formControlName="js_remarks" class="form-control" name="js_remarks" type="text" required></textarea>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <label class="col-sm-3 col-form-label" for="file">Attachment (Optional)</label>
                            <div class="col-sm-9">
                                <file-pond
                                    #myPond
                                    [options]="pondOptions"
                                    [files]=""
                                    (oninit)="pondHandleInit()"
                                    (onaddfile)="pondHandleAddFile($event)"
                                >
                                </file-pond>
                            </div>
                        </div>

                        <div class="row justify-content-end">
                            <div class="col-sm-9">
                
                                <div class="btn-group btn-block">
                                    <button id="submituser" type="submit" class="btn btn-primary  elevation-1">Submit</button>
                                    <a type="submit" class="btn btn-danger elevation-1" onclick="window.history.back()">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

