<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Jobsheet Details</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active">Jobsheet Detail</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <div class="container-fluid">
        <div class="invoice p-3 mb-3">
            <!-- title row -->
            <div class="row">
              <div class="col-12">
                <h4>
                  Jobsheet No: #{{js.js_id}}
                  <small class="float-right">Date: {{js.js_created}}</small>
                </h4>
              </div>
              <!-- /.col -->
            </div>
            <hr>
            <!-- info row -->
            <div class="row invoice-info">
              <div class="col-sm-4 invoice-col">
                Issue :
                <address>
                  <strong>{{js.issue_title}}</strong><br>
                  {{js.issue_desc}}<br>
                  {{js.issue_location}}

                </address>
              </div>
              <!-- /.col -->
              <div class="col-sm-4 invoice-col">
                Created By
                <p>
                    <strong>{{js.createdby}}</strong>
                </p>
                Assign To: 
                <p>
                    <strong>{{js.assign}}</strong>
                </p>
                    
              </div>
              <!-- /.col -->
              <div class="col-sm-4 invoice-col">
                <b>Ticket: {{js.issue_ticket}}</b><br>
                <br>
                <b>Status:</b> Closed<br>
                <b>Last Updated:</b> 2/22/2014<br>
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->

            <!-- Table row -->
            <div class="row">
              <div class="col-12 table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th colspan="2">Description</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="bg-secondary" style="width: 130px; border-right: solid 1px #000;">Jobsheet No.</td>
                    <td>{{js.js_id}}</td>
                  </tr>
                  <tr>
                    <td class="bg-secondary" style="width: 130px; border-right: solid 1px #000;">Created Date</td>
                    <td>{{js.js_created}}</td>
                  </tr>
                  <tr>
                    <td class="bg-secondary" style="width: 130px; border-right: solid 1px #000;">Open By</td>
                    <td>{{js.opener}}</td>
                  </tr>
                  <tr>
                    <td class="bg-secondary" style="width: 130px; border-right: solid 1px #000;">Services</td>
                    <td>{{js.js_services}}</td>
                  </tr>
                  <tr>
                    <td class="bg-secondary" style="width: 130px; border-right: solid 1px #000;">Workdone</td>
                    <td>{{js.js_workdone}}</td>
                  </tr>
                  <tr>
                    <td class="bg-secondary" style="width: 130px; border-right: solid 1px #000;">Remarks</td>
                    <td>{{js.js_remarks}}</td>
                  </tr>
                  <tr>
                    <td class="bg-secondary" style="width: 130px; border-right: solid 1px #000;">Attachment</td>
                    <td>Under Maintenance</td>
                  </tr>
                  
                  </tbody>
                </table>
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
              <!-- accepted payments column -->
              <div class="col-6">
                <p class="lead">Management Remarks:</p>

                <p class="text-muted well well-sm shadow-none" style="margin-top: 10px;">
                  Coming soon
                </p>
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->

            <!-- this row will not appear when printing -->
            <div class="row no-print" hidden>
              <div class="col-12">
                <button type="button" class="btn btn-success float-right"><i class="far fa-credit-card"></i> Acknowledge
                </button>
              </div>
            </div>
          </div>
    </div>
</section>