import {Component} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {AppService} from '@services/app.service';
import {ToastrService} from 'ngx-toastr';



@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

    public user: any;
    public changePasswordForm: FormGroup;
    public isAuthLoading = false;
  
    constructor(
      private toastr: ToastrService,
      private appService: AppService
    ) { }

    ngOnInit(): void {
        this.user = this.appService.user;

        this.changePasswordForm = new FormGroup({
            user_name: new FormControl(null, Validators.required),
            user_email: new FormControl(null, Validators.required),
            user_password: new FormControl(null, Validators.required),
            new_password: new FormControl(null, Validators.required),
            retype_password: new FormControl(null, Validators.required),
            user_level: new FormControl(null, Validators.required)
          });
        
        this.setValue();
          
    }

    async changePasswordByPHP(){
        if (this.changePasswordForm.valid) {
            this.isAuthLoading = true;
            console.log(this.changePasswordForm.value)
            await this.appService.changePass(this.changePasswordForm.value);
            this.isAuthLoading = false;
        } else {
            this.toastr.error('Form is not valid!');
        }

    }

    async setValue(){
        this.changePasswordForm.controls['user_name'].setValue(this.user.user_name);
        this.changePasswordForm.controls['user_email'].setValue(this.user.user_email);
        this.changePasswordForm.controls['user_level'].setValue(this.user.user_level);
    }
}
