import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-jobsheet-list',
  templateUrl: './jobsheet-list.component.html',
  styleUrls: ['./jobsheet-list.component.scss']
})
export class JobsheetListComponent implements OnInit {

  public jobsheets:any
  constructor(
    private toastr: ToastrService,
    private appService: AppService,
    private router : Router
  ) { }

  ngOnInit(): void {
    this.getJS()
  }

  async getJS(){
    this.jobsheets = await this.appService.getJS();
    console.log(this.jobsheets)
  }
  
  change(val: any){
    return new Date(val).toLocaleString('en-MY')
  }

  viewjs(id: any){
    console.log(id)
    sessionStorage.tmp_js = id
    this.router.navigate(['/jobsheet-view'])
  }

}
