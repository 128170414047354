import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild  } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { FilePond, FilePondOptions} from 'filepond';


@Component({
  selector: 'app-jobsheet-register',
  templateUrl: './jobsheet-register.component.html',
  styleUrls: ['./jobsheet-register.component.scss']
})
export class JobsheetRegisterComponent implements OnInit {

  public registerForm: FormGroup;
  public isAuthLoading = false;
  public users: any
  public issues: any =false
  public blob : Blob
  public services:any = [
    {
      "id": "1",
      "name": "Routine Work"
    },{
      "id": "2",
      "name": "Service schedule"
    },{
      "id": "3",
      "name": "Breakdown"
    },{
      "id": "4",
      "name": "Training"
    },{
      "id": "5",
      "name": "Others"
    },
  ]

  fileToUpload: File | null = null;

  constructor(
    private toastr: ToastrService,
    private appService: AppService,
    private http : HttpClient,
  ) { }

  @ViewChild('myPond')
  myPond!: FilePond;
  
  pondOptions: FilePondOptions = {
    allowMultiple: true,
    labelIdle: 'Drop files here...',
    acceptedFileTypes: ['image/*'],
    allowReorder:true,
    maxFiles:1,
    fileRenameFunction: (file) => {
      let name = new Date().getTime()
      return `${name+file.extension}`;
    },
    //server: 'http://localhost/apis'
  }

  

  pondHandleInit() {
    console.log('FilePond has initialised', this.myPond);
  }

  pondHandleAddFile(event: any) {
    console.log('A file was added', event);
    this.uploadFiles()
  }

  pondHandleRemoveFile(event: any) {
    console.log('A file was removed', event);
  }

  pondHandleActivateFile(event: any) {
    console.log('A file was activated', event)
  }

  uploadFiles(){
    console.log(this.myPond.getFiles());
    let fi = this.myPond.getFiles();
    console.log(fi[0])
    this.blob = fi[0].file
    this.registerForm.controls.js_file.setValue(fi[0].fileExtension)

  }

  




  ngOnInit(): void {
    
    this.setting()
    this.getIssues()
    if(sessionStorage.temp_issue_id){
      this.registerForm.controls.issue_id.setValue(sessionStorage.temp_issue_id);
      this.popIssue(sessionStorage.temp_issue_id)
      sessionStorage.removeItem('temp_issue_id')
    }
  }

  setting(){
    this.registerForm = new FormGroup({
      issue_id: new FormControl(null, Validators.required),
      issue_title: new FormControl(null, Validators.required),
      issue_desc: new FormControl(null, Validators.required),
      issue_location: new FormControl(null, Validators.required),
      js_workdone: new FormControl(null, Validators.required),
      js_services: new FormControl(null, Validators.required),
      js_remarks: new FormControl(null, Validators.required),
      js_file: new FormControl(null),
    });
  }

  async newjobsheetByPHP() {
    console.log(this.registerForm.value)

    if (this.registerForm.valid) {
        this.isAuthLoading = true;
        await this.appService.newjobsheetByPHP(this.registerForm.value, this.blob);
        this.isAuthLoading = false;
    } else {
        this.toastr.error('Form is not valid!');
    }
  }

  async getUsers(){
    this.users = await this.appService.getUser();
  }

  async getIssues(){
    this.issues = await this.appService.getIssues();
    console.log(this.issues)
  }

  async handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload)
    
  }

  async popIssue(x:any){
    
    let p = await this.appService.getIssueDetails(x)
    this.registerForm.controls.issue_title.setValue(p[0].issue_title);
    this.registerForm.controls.issue_desc.setValue(p[0].issue_desc);
    this.registerForm.controls.issue_location.setValue(p[0].issue_location);

  }

  async sendTodb(){
    const formData = new FormData();
    formData.append('file', this.registerForm.get('js_file').value);
    formData.append('request_type', 'register_jobsheet')


    this.http.post('http://localhost/internal-restapi/controller.php', formData)
    .subscribe(res => {
      console.log(res);
      alert('Uploaded Successfully.');
    })

  }

}
