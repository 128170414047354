import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    public user: any;
    public menu = MENU;

    constructor(public appService: AppService) {}

    ngOnInit() {
        this.user = this.appService.user;
    }
}

export const MENU = [
    {
        name: 'Dashboard',
        icon: 'fas fa-home',
        path: ['/']
    },
    {
        name: 'User Management',
        icon: 'fas fa-users',
        children: [
            {
                name: 'User List',
                path: ['/users']
            },

            {
                name: 'Register User',
                path: ['/register']
            }
        ]
    },
    {
        name: 'Ticket Management',
        icon: 'fas fa-clipboard-list',
        children: [
            {
                name: 'Ticket List',
                path: ['/issue-list']
            },

            {
                name: 'Create Ticket',
                path: ['/issue-reg']
            }
        ]
    },

    {
        name: 'Job Sheet',
        icon: 'fas fa-clipboard-check',
        children: [
            {
                name: 'Job Sheet List',
                path: ['/jobsheet-list']
            },

            {
                name: 'Create Job Sheet',
                path: ['/jobsheet-new']
            }
        ]
    },
    
];
