import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit {

  public registerForm: FormGroup;
  public isAuthLoading = false;

  constructor(
    private toastr: ToastrService,
    private appService: AppService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if(localStorage.tech == 0){
      this.toastr.error('No permission to enter');
      this.router.navigate(['/']);
      return
    }
    this.registerForm = new FormGroup({
      user_name: new FormControl(null, Validators.required),
      user_email: new FormControl(null, Validators.required),
      user_level: new FormControl(null, Validators.required),
      password: new FormControl(null, [Validators.required]),
      retypepassword: new FormControl(null, [Validators.required])
    });
  }

  async registerUserByPHP() {
    if (this.registerForm.valid) {
        this.isAuthLoading = true;
        console.log(this.registerForm.value)
        await this.appService.registerByPHP(this.registerForm.value);
        this.isAuthLoading = false;
    } else {
        this.toastr.error('Form is not valid!');
    }
  }

  

}
