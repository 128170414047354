
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>New Ticket</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">New ticket</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        
        <div class="row">
            
            <div class="col-12">
                <div class="card card-body">
     
                    <form id="user_register" [formGroup]="registerForm" (ngSubmit)="newissueByPHP()">
                        <div class="row mb-4">
                            <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Ticket Title</label>
                            <div class="col-sm-9">
                                <input formControlName="issue_title" class="form-control" name="issue_title" type="text" placeholder="eg. Pipe burst" maxlength="50" autocomplete="off" required>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <label for="horizontal-email-input" class="col-sm-3 col-form-label">Ticket Description</label>
                            <div class="col-sm-9">
                                <textarea formControlName="issue_desc" class="form-control" name="issue_desc" type="text" required></textarea>
                            </div>
                        </div>
                
                        <div class="row mb-4">
                            <label for="horizontal-email-input" class="col-sm-3 col-form-label">Location</label>
                            <div class="col-sm-9">
                                <input formControlName="issue_location" class="form-control" name="issue_location" type="text" placeholder="eg. Guard House" autocomplete="off" required>
                            </div>
                        </div>
            
                        <div class="row mb-4">
                            <label for="horizontal-password-input" class="col-sm-3 col-form-label">Assign Technician</label>
                            <div class="col-sm-9">
                            <select formControlName="user_assign" id="level" class="form-control" name="user_assign" placeholder="Please Select" required>
                                <option disabled>Please Select</option>
                                <option value="{{user.user_id}}" *ngFor="let user of users">{{user.user_name}}</option>
                                         
                            </select>
                            </div>
                        </div>
                
                
                        <div class="row justify-content-end">
                            <div class="col-sm-9">
                
                                <div class="btn-group btn-block">
                                    <button id="submituser" type="submit" class="btn btn-primary  elevation-1">Submit</button>
                                    <a type="submit" class="btn btn-danger elevation-1" onclick="window.history.back()">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

