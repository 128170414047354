<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Profile</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">User Profile</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <!-- Profile Image -->
                <div class="card card-primary card-outline">
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <img
                                class="profile-user-img img-fluid img-circle"
                                src="assets/img/default-profile.png"
                                alt="User profile picture"
                            />
                        </div>

                        <h3 class="profile-username text-center">
                            {{user.user_name}}
                        </h3>

                        <p class="text-muted text-center"> Last Login: <br> {{user.user_lastlogin}}</p>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->

                <!-- About Me Box -->
                <div class="card card-primary" hidden>
                    <div class="card-header">
                        <h3 class="card-title">About Me</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <strong
                            ><i class="fas fa-book mr-1"></i> Education</strong
                        >

                        <p class="text-muted">
                            B.S. in Computer Science from the University of
                            Tennessee at Knoxville
                        </p>

                        <hr />

                        <strong
                            ><i class="fas fa-map-marker-alt mr-1"></i>
                            Location</strong
                        >

                        <p class="text-muted">Malibu, California</p>

                        <hr />

                        <strong
                            ><i class="fas fa-pencil-alt mr-1"></i>
                            Skills</strong
                        >

                        <p class="text-muted">
                            <span class="tag tag-danger">UI Design</span>
                            <span class="tag tag-success">Coding</span>
                            <span class="tag tag-info">Javascript</span>
                            <span class="tag tag-warning">PHP</span>
                            <span class="tag tag-primary">Node.js</span>
                        </p>

                        <hr />

                        <strong
                            ><i class="far fa-file-alt mr-1"></i> Notes</strong
                        >

                        <p class="text-muted">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Etiam fermentum enim neque.
                        </p>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
            <div class="col-md-9">
                <div class="card">
                    <div class="card-header p-2">
                        <!--<ul class="nav nav-pills">
                            <li class="nav-item">
                                <a
                                    class="nav-link active"
                                    href="#activity"
                                    data-toggle="tab"
                                    >Activity</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    href="#timeline"
                                    data-toggle="tab"
                                    >Timeline</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    href="#settings"
                                    data-toggle="tab"
                                    >Settings</a
                                >
                            </li>
                        </ul>-->
                        <h3>Update Profile</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="tab-content">
                            <form id="user_register" [formGroup]="changePasswordForm" (ngSubmit)="changePasswordByPHP()">
                                <div class="row mb-4">
                                    <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Name</label>
                                    <div class="col-sm-9">
                                        <input formControlName="user_name" class="form-control" name="user_name" type="text" maxlength="50" autocomplete="off" required>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <label for="horizontal-email-input" class="col-sm-3 col-form-label">Email</label>
                                    <div class="col-sm-9">
                                        <input formControlName="user_email" class="form-control" name="user_email" type="email" required>
                                    </div>
                                </div>

                                <div class="row mb-4" hidden>
                                    <label for="horizontal-password-input" class="col-sm-3 col-form-label">User Level</label>
                                    <div class="col-sm-9">
                                    <select formControlName="user_level" id="level" class="form-control" name="user_level" placeholder="Please Select" required>
                                        <option value="1">Admin</option>
                                        <option value="0">Technician</option>                          
                                    </select>
                                    </div>
                                </div>
                                <hr>
                                <div class="row mb-4">
                                    <label for="horizontal-email-input" class="col-sm-3 col-form-label">Old Password</label>
                                    <div class="col-sm-9">
                                        <input formControlName="user_password" class="form-control" name="user_password" type="password" required>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <label for="horizontal-email-input" class="col-sm-3 col-form-label">New Password</label>
                                    <div class="col-sm-9">
                                        <input formControlName="new_password" class="form-control" name="new_password" type="password" required>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <label for="horizontal-email-input" class="col-sm-3 col-form-label">Retype Password</label>
                                    <div class="col-sm-9">
                                        <input formControlName="retype_password" class="form-control" name="retype_password" type="password" required>
                                    </div>
                                </div>

                                <div class="row justify-content-end">
                                    <div class="col-sm-9">
                        
                                        <div class="btn-group btn-block">
                                            <button id="submituser" type="submit" class="btn btn-primary  elevation-1">Update</button>
                                            <a type="submit" class="btn btn-danger elevation-1" onclick="window.history.back()">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <!-- /.tab-content -->
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.nav-tabs-custom -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
