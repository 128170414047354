<app-dropdown size="lg">
    <ng-container dropdown-button>
        <i class="far fa-bell"></i>
        <span class="badge badge-danger navbar-badge">0</span>
    </ng-container>
    <ng-container dropdown-menu>
        <span class="dropdown-item dropdown-header">0 Notifications</span>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 0 new messages
            <span class="float-right text-muted text-sm">0 mins</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item dropdown-footer"
            >See All Notifications</a
        >
    </ng-container>
</app-dropdown>

<ng-template #notification>
    <a href="#" class="dropdown-item">
        <i class="fas fa-envelope mr-2"></i> 4 new messages
        <span class="float-right text-muted text-sm">3 mins</span>
    </a>
    <div class="dropdown-divider"></div>
</ng-template>
