import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@services/app.service';



@Component({
  selector: 'app-issue-view',
  templateUrl: './issue-view.component.html',
  styleUrls: ['./issue-view.component.scss']
})
export class IssueViewComponent implements OnInit {
  public id:any
  public details:any
  public jss:any
  constructor(
    private route : ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private toastr : ToastrService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getIssueDetails(this.id)
    this.getJS()
  }

  async getIssueDetails(id:any) {
    let data = await this.appService.getIssueDetails(this.id);
    this.details = data[0]
  }

  async getJS(){
    let data = await this.appService.getJSDetailsForIssue(this.id);
    console.log(data)
    this.jss = data
  }

  change(val){
    return new Date(val).toLocaleString('en-MY')
  }

  async closIssue(id:any){
    if(localStorage.tech == 0){
      this.toastr.error('No permission to closed ticket');
      this.router.navigate(['/']);
      return
    }
    console.log(id)
    let data = await this.appService.closedIssue(this.id);
    console.log(data)
  }

  openJs(id:any){
    sessionStorage.temp_issue_id = id
    this.router.navigate(['/jobsheet-new']);
  }

  date(data: any){
    return new Date(data).toLocaleString('en-MY')
  }

}
