import { filter } from 'rxjs/operators';
import { AppService } from '@services/app.service';
import {Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import { Router } from '@angular/router';



@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

    public t_issues : any
    public t_js : any
    public issues: any
    public jobsheets : any

    constructor(
        private toastr: ToastrService,
        private appService: AppService,
        private router : Router
      ) { }
    
      ngOnInit(): void {
        this.getIssues()
        this.getJS()
      }

      async getIssues(){
        let dt = await this.appService.getIssues();
        if(dt == 'No Data'){
          this.issues = 'No Data'
          this.t_issues = 0
          return
        }
        this.t_issues = dt.length
        let arr = []
        for(let x in dt){
          if(dt[x].issue_status < 4){
            arr.push(dt[x])
          }
        }
        if(arr.length == 0){
          this.issues = 'No Data'
          this.t_issues = 0
          return
        }

        this.issues = arr
        console.log(this.issues)
        
      }

      async getJS(){
        this.jobsheets = await this.appService.getJS();
        console.log(this.jobsheets)
        if(this.jobsheets == 'No Data'){
          return this.t_js = 0
        }

        this.t_js = this.jobsheets.length
      }
      
      change(val){
        return new Date(val).toLocaleString('en-MY')
      }

      viewjs(id: any){
        console.log(id)
        sessionStorage.tmp_js = id
        this.router.navigate(['/jobsheet-view'])
      }
}
