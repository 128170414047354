import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-issue-register',
  templateUrl: './issue-register.component.html',
  styleUrls: ['./issue-register.component.scss']
})
export class IssueRegisterComponent implements OnInit {

  public registerForm: FormGroup;
  public isAuthLoading = false;
  public users: any

  constructor(
    private toastr: ToastrService,
    private appService: AppService,
    private router:Router
  ) { }

  ngOnInit(): void {
    if(localStorage.tech == 0){
      this.toastr.error('No permission to enter');
      this.router.navigate(['/']);
      return
    }
    this.registerForm = new FormGroup({
      issue_title: new FormControl(null, Validators.required),
      issue_desc: new FormControl(null, Validators.required),
      user_assign: new FormControl(null, Validators.required),
      issue_location: new FormControl(null, Validators.required),
    });

    this.getUsers();

  }

  async newissueByPHP() {
    if (this.registerForm.valid) {
        this.isAuthLoading = true;
        console.log(this.registerForm.value)
        await this.appService.newissueByPHP(this.registerForm.value);
        this.isAuthLoading = false;
    } else {
        this.toastr.error('Form is not valid!');
    }
  }

  async getUsers(){
    let f = await this.appService.getUser();
    console.log(f)
    this.users = f.filter(function(e : any){
      
      return e.user_level == 0
    })
    console.log(this.users)
  }

}
