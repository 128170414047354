
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>User Registration</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">User registration</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        
        <div class="row">
            
            <div class="col-12">
                <div class="card card-body">
     
                    <form id="user_register" [formGroup]="registerForm" (ngSubmit)="registerUserByPHP()">
                        <div class="row mb-4">
                            <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                                <input formControlName="user_name" class="form-control" name="user_name" type="text" placeholder="eg. Ahmad Fikri Bin Talha" maxlength="50" autocomplete="off" required>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <label for="horizontal-email-input" class="col-sm-3 col-form-label">Email <span style="font-size:10px; color: red"><i> **Email as Login ID</i></span></label>
                            <div class="col-sm-9">
                                <input formControlName="user_email" class="form-control" name="user_email" type="email" placeholder="eg. fikri@gmail.com" required>
                            </div>
                        </div>
                
                        <div class="row mb-4">
                            <label for="horizontal-email-input" class="col-sm-3 col-form-label">Temporary Password</label>
                            <div class="col-sm-9">
                                <input formControlName="password" class="form-control" name="password" type="password" placeholder="*********" autocomplete="off" required>
                            </div>
                        </div>
                
                        <div class="row mb-4">
                            <label for="horizontal-email-input" class="col-sm-3 col-form-label">Repeat Temporary Password</label>
                            <div class="col-sm-9">
                                <input formControlName="retypepassword" class="form-control" name="retypepassword" type="password" placeholder="*********" required>
                            </div>
                        </div>

                
                        <div class="row mb-4">
                            <label for="horizontal-password-input" class="col-sm-3 col-form-label">User Level</label>
                            <div class="col-sm-9">
                            <select formControlName="user_level" id="level" class="form-control" name="user_level" placeholder="Please Select" required>
                                <option value="1">Admin</option>
                                <option value="0">Technician</option>
                                         
                            </select>
                            </div>
                        </div>
                
                
                        <div class="row justify-content-end">
                            <div class="col-sm-9">
                
                                <div class="btn-group btn-block">
                                    <button id="submituser" type="submit" class="btn btn-primary  elevation-1">Submit</button>
                                    <a type="submit" class="btn btn-danger elevation-1" onclick="window.history.back()">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

