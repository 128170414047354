import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {  Router, ActivatedRoute, ParamMap  } from '@angular/router';
import { AppService } from '@services/app.service';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  public updateForm : FormGroup
  id: any
  isAuthLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private appservice : AppService,
    private toastr : ToastrService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getUserData(this.id)

    this.updateForm = new FormGroup({
      user_name: new FormControl(null, Validators.required),
      user_email: new FormControl(null, Validators.required),
      user_level: new FormControl(null, Validators.required),
    });


  }

  async updateUserByPHP(){
    if (this.updateForm.valid) {
      this.isAuthLoading = true;
      console.log(this.updateForm.value)
      await this.appservice.updateUserByPHP(this.updateForm.value, this.id);
      this.isAuthLoading = false;
    } else {
        this.toastr.error('Form is not valid!');
    }
  }

  async getUserData(id: any){
    let user = await this.appservice.getUserDetails(id)
    this.updateForm.controls.user_name.setValue(user[0].user_name);
    this.updateForm.controls.user_email.setValue(user[0].user_email)
    this.updateForm.controls.user_level.setValue(user[0].user_level)
  }

}
