import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';

@Component({
  selector: 'app-issue-list',
  templateUrl: './issue-list.component.html',
  styleUrls: ['./issue-list.component.scss']
})
export class IssueListComponent implements OnInit {
  public issues : any
  constructor(
    private toastr: ToastrService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.getIssues()
  }

  async getIssues(){
    this.issues = await this.appService.getIssues();
    console.log(this.issues)
  }

}
