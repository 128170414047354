import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    private url : any = '/internal-restapi/controller.php';
    //private url : any = 'http://localhost/internal-restapi/controller.php';

    constructor(private router: Router, private toastr: ToastrService, private httpClient: HttpClient) {}



    async loginByPHP({email, password}) {
        let data = JSON.stringify({
            "user_email": email,
            "user_password": password,
            "request_type":"login_user",
        })
        try {  
            const result = await fetch(this.url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: data
            });

            let response = await result.json()

            if(response.status == 'Email/Password Incorrect'){
                this.toastr.error(response.status);
                return
            }
            localStorage.setItem('token', response.status.user_unique);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByPHP({user_email, password, user_level, user_name}) {
        let data = JSON.stringify({
            "user_email": user_email,
            "user_password": password,
            "request_type":"register_user",
            "user_name": user_name,
            "user_level": user_level
        })

        try {  
            const result = await fetch(this.url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.token
                },
                body: data
            });
            let response = await result.json()
            if(response.status == 'success'){
                this.toastr.success('Registration Success');
                this.router.navigate(['/users']);
            }else{
                this.toastr.error(response.status);
            }
            
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async updateUserByPHP({user_email, user_level, user_name}, id:any) {
        let data = JSON.stringify({
            "user_email": user_email,
            "request_type":"update_user",
            "user_name": user_name,
            "user_level": user_level,
            "user_id": id
        })

        try {  
            const result = await fetch(this.url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.token
                },
                body: data
            });
            let response = await result.json()
            if(response.status == 'success'){
                this.toastr.success('Update Success');
                this.router.navigate(['/users']);
            }else{
                this.toastr.error(response.status);
            }
            
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async newissueByPHP({issue_title, issue_desc, issue_location, user_assign}) {
        let data = JSON.stringify({
            "issue_title": issue_title,
            "issue_desc": issue_desc,
            "request_type":"new_issue",
            "issue_location": issue_location,
            "user_assign": user_assign,
            "issue_createdby": localStorage.user_id
        })

        try {  
            const result = await fetch(this.url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.token
                },
                body: data
            });
            let response = await result.json()
            if(response.status == 'success'){
                this.toastr.success('New Issue Created');
                this.router.navigate(['/issue-list']);
            }else{
                this.toastr.error(response.status);
            }
            
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async newjobsheetByPHP({issue_id, js_workdone, js_services, js_remarks, js_file}, blob:Blob) {
        let data = {
            "issue_id": issue_id,
            "js_workdone": js_workdone,
            "request_type":"new_jobsheet",
            "js_services": js_services,
            "js_remarks": js_remarks,
            "js_createdby": localStorage.user_id,
            "js_file": js_file
        }
        const formData = new FormData();
        for(let x in data){
            formData.append(x, data[x]);
        }
        formData.append('file', blob);

        try {  
            const result = await fetch(this.url, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer '+localStorage.token
                },
                body: formData
            });
            let response = await result.json()
            if(response.status == 'success'){
                this.toastr.success('New Jobsheet Created');
                this.router.navigate(['/jobsheet-list']);
            }else{
                this.toastr.error(response.status);
            }
            
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async closedIssue(issue_id : any) {
        let data = JSON.stringify({
            "issue_id": issue_id,
            "request_type":"closed_ticket",
        })
        try {  
            const result = await fetch(this.url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.token
                },
                body: data
            });
            console.log(result)
            let response = await result.json()

            
            if(response.status == 'success'){
                this.toastr.success('Ticket Closed');
                this.router.navigate(['/issue-list']);
            }else{
                this.toastr.error(response.status);
            }
            
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async changePass({user_name, user_email, user_password, new_password, retype_password, user_level}){
        let data = JSON.stringify({
            "user_email": user_email,
            "user_password": user_password,
            "new_password": new_password,
            "retype_password": retype_password,
            "request_type":"change_password",
            "user_name": user_name,
            "user_level": user_level
        })

        if(new_password !== retype_password){
            return this.toastr.error('New Password Missmatch');
        }

        try {  
            const result = await fetch(this.url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.token
                },
                body: data
            });
            let response = await result.json()
            if(response.status == 'success'){
                this.toastr.success('Profile updated');
                this.router.navigate(['/profile']);
            }else{
                this.toastr.error(response.status);
            }
            
        } catch (error) {
            this.toastr.error(error.message);
        }
    }


    async getProfile() {
        try {
            let response = await fetch(this.url+'?req=auth', { 
                method: 'GET', 
                headers: new Headers({
                  'Authorization': 'Bearer '+localStorage.token
                })
            });

            
            this.user = await response.json();
            console.log(this.user)
            localStorage.user_id = this.user.user_id
            localStorage.tech = this.user.user_level

            if(this.user == 'Invalid'){
                this.logout();  
            }
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    async getUser() {
        try {
            let response = await fetch(this.url+'?req=users', { 
                method: 'GET', 
                headers: new Headers({
                  'Authorization': 'Bearer '+localStorage.token
                })
            });
            let result = await response.json();
            return result

        } catch (error) {
            throw error;
        }
    }

    async getUserDetails(id:any) {
        try {
            let response = await fetch(this.url+'?req=usersDetails&id='+id, { 
                method: 'GET', 
                headers: new Headers({
                  'Authorization': 'Bearer '+localStorage.token
                })
            });
            let result = await response.json();
            return result

        } catch (error) {
            throw error;
        }
    }

    

    async getIssueDetails(id:any) {
        try {
            let response = await fetch(this.url+'?req=issuedetails&id='+id, { 
                method: 'GET', 
                headers: new Headers({
                  'Authorization': 'Bearer '+localStorage.token
                })
            });
            let result = await response.json();
            return result

        } catch (error) {
            throw error;
        }
    }

    async getJSDetailsForIssue(id:any) {
        try {
            let response = await fetch(this.url+'?req=jobsheetdetailsForIssue&id='+id, { 
                method: 'GET', 
                headers: new Headers({
                  'Authorization': 'Bearer '+localStorage.token
                })
            });
            let result = await response.json();
            return result

        } catch (error) {
            throw error;
        }
    }

    

    async getIssues() {
        try {
            let response = await fetch(this.url+'?req=issues', { 
                method: 'GET', 
                headers: new Headers({
                  'Authorization': 'Bearer '+localStorage.token
                })
            });
            let result = await response.json();
            return result

        } catch (error) {
            throw error;
        }
    }

    async getJS() {
        try {
            let response = await fetch(this.url+'?req=jobsheet', { 
                method: 'GET', 
                headers: new Headers({
                  'Authorization': 'Bearer '+localStorage.token
                })
            });
            let result = await response.json();
            return result

        } catch (error) {
            throw error;
        }
    }

    async getJSDetails(id:any) {
        try {
            let response = await fetch(this.url+'?req=jobsheetdetails&id='+id, { 
                method: 'GET', 
                headers: new Headers({
                  'Authorization': 'Bearer '+localStorage.token
                })
            });
            let result = await response.json();
            console.log(result)
            return result

        } catch (error) {
            throw error;
        }
    }



// not use
   async loginByAuth({email, password}) {
        try {
            const token = await Gatekeeper.loginByAuth(email, password);
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByAuth({email, password}) {
        try {
            const token = await Gatekeeper.registerByAuth(email, password);
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByGoogle() {
        try {
            const token = await Gatekeeper.loginByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByGoogle() {
        try {
            const token = await Gatekeeper.registerByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByFacebook() {
        try {
            const token = await Gatekeeper.loginByFacebook();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByFacebook() {
        try {
            const token = await Gatekeeper.registerByFacebook();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async getProfile2() {
        try {
            this.user = await Gatekeeper.getProfile();
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/login']);
    }
}
