import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  users : any ;
  constructor(
    private toastr: ToastrService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.loginByPHP();
  }

  async loginByPHP() {
    let res = await this.appService.getUser();
    console.log(res)
    this.users = res
  }


}
