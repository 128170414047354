
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Ticket List</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active">Ticket list</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
                <div class="card-title">
                  <button [routerLink]="['/issue-reg']" class="btn btn-primary elevation-1"> <i class="fas fa-plus"></i> New Ticket</button>
                </div>
    
              <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 190px;">
                  <input type="text" name="table_search" class="form-control float-right" placeholder="Search">
    
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-default">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-2">
              <table class="table table-hover text-nowrap border">
                <thead class="card-outline card-primary">
                  <tr>
                    <th>#No</th>
                    <th>Ticket</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Created By</th>
                    <th>Assign To</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody *ngIf="issues !== 'No Data' else nodata">
                  <tr *ngFor ="let issue of issues">
                    <td>#{{issue.issue_id}}</td>
                    <td>#{{issue.issue_ticket}}</td>
                    <td>{{issue.issue_title}}</td>
                    <td>{{issue.issue_desc}}</td>
                    <td>{{issue.createdby}}</td>
                    <td>{{issue.assign}}</td>
                    <td *ngIf="issue.issue_status == 0;else aa">New</td>
                    <ng-template #aa ><td *ngIf="issue.issue_status == 1;else bb">In Progress</td></ng-template>
                    <ng-template #bb ><td *ngIf="issue.issue_status == 2;else cc">Completed</td></ng-template>
                    <ng-template #cc ><td>Closed</td></ng-template>
                    <td>
                        <button class="btn btn-primary" [routerLink]="['/issue-view', issue.issue_id]">View</button>
                    </td>
                  </tr>
                </tbody>
                <ng-template #nodata>
                    <tr>
                        <td colspan="7">No data at this moment</td>
                    </tr>
                </ng-template>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>
</div>
</section>
