import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { UserListComponent } from '@pages/user/user-list/user-list.component';
import { UserRegisterComponent } from '@pages/user/user-register/user-register.component';
import { UserEditComponent } from '@pages/user/user-edit/user-edit.component';
import { IssueListComponent } from '@pages/issue/issue-list/issue-list.component';
import { IssueRegisterComponent } from '@pages/issue/issue-register/issue-register.component';
import { JobsheetListComponent } from '@pages/jobsheet/jobsheet-list/jobsheet-list.component';
import { JobsheetRegisterComponent } from '@pages/jobsheet/jobsheet-register/jobsheet-register.component';
import { IssueViewComponent } from '@pages/issue/issue-view/issue-view.component';
import { JobsheetViewComponent } from '@pages/jobsheet/jobsheet-view/jobsheet-view.component';




const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'users',
                component: UserListComponent
            },
            {
                path: 'register',
                component: UserRegisterComponent
            },
            {
                path: 'edit/:id',
                component: UserEditComponent,
            },
            {
                path: 'issue-list',
                component: IssueListComponent,
            },
            {
                path: 'issue-reg',
                component: IssueRegisterComponent,
            },
            {
                path: 'issue-view/:id',
                component: IssueViewComponent,
            },
            {
                path: 'jobsheet-new',
                component: JobsheetRegisterComponent
            },
            {
                path: 'jobsheet-list',
                component: JobsheetListComponent
            },
            {
                path: 'jobsheet-view',
                component: JobsheetViewComponent
            },
            {
                path: '',
                component: DashboardComponent
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
