<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Ticket Detail</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active">Ticket Detail</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>


<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!-- Default box -->
                <div class="card" *ngIf="details">
                    <div class="card-header">
                        <h3 class="card-title">Ticket Detail No #{{id}} - {{details.issue_ticket}}</h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                            <button type="button" class="btn btn-tool" data-card-widget="remove" title="Remove">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body" >
                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-4 order-1 order-md-1 border-right">
                                <h5 *ngIf="details.issue_status == 0;else aa" class="badge bg-danger">New Ticket</h5>
                                <ng-template #aa>
                                    <h5 *ngIf="details.issue_status == 1;else bb" class="badge bg-warning">In Progress
                                    </h5>
                                </ng-template>
                                <ng-template #bb>
                                    <h5 class="badge bg-success">Completed</h5>
                                </ng-template>
                                <div class="callout callout-secondary">
                                    <h5><b>{{details.issue_title}}</b></h5>
                    
                                    <p class="">Issue details:
                                        <span class="d-block">{{details.issue_desc}}</span></p>
                                    <p class="text-sm">Location
                                        <b class="d-block">{{details.issue_location}}</b>
                                    </p>
                                </div>
                                
                                <div class="text-light p-1">
                                    <div class="text-muted">
                                        <p class="text-sm">Created Date
                                            <b class="d-block">{{date(details.issue_created)}}</b>
                                        </p>
                                        <p class="text-sm">Last Updated
                                            <b class="d-block">{{date(details.issue_timestamp)}}</b>
                                        </p>
                                        
                                        <p class="text-sm">Created By
                                            <b class="d-block">{{details.createdby}}</b>
                                        </p>
                                        <p class="text-sm">Assign To
                                            <b class="d-block">{{details.assign}}</b>
                                        </p>
                                    </div>
                                </div>

                                <br>
                                

                                <!--<h5 class="mt-5 text-muted">Attachment</h5>
                                <ul class="list-unstyled">
                                    <li>
                                        <a href="" class="btn-link text-secondary"><i
                                                class="far fa-fw fa-file-word"></i> Functional-requirements.docx</a>
                                    </li>
                                </ul>-->
                                <div class="text-center mt-5 mb-3 btn-group">
                                    <a (click)="openJs(id)" class="btn btn-sm btn-warning">Open jobsheet</a>
                                    <a (click)="closIssue(id)" class="btn btn-sm btn-danger">Close Issue</a>
                                </div>
                            </div>
                            <div class="col-12 col-md-12 col-lg-8 order-2 order-md-2">
                                <!--<div class="row" hidden>
                                    <div class="col-12 col-sm-4">
                                        <div class="info-box bg-light">
                                            <div class="info-box-content">
                                                <span class="info-box-text text-center text-muted">Estimated
                                                    budget</span>
                                                <span class="info-box-number text-center text-muted mb-0">2300</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <div class="info-box bg-light">
                                            <div class="info-box-content">
                                                <span class="info-box-text text-center text-muted">Total amount
                                                    spent</span>
                                                <span class="info-box-number text-center text-muted mb-0">2000</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <div class="info-box bg-light">
                                            <div class="info-box-content">
                                                <span class="info-box-text text-center text-muted">Estimated project
                                                    duration</span>
                                                <span class="info-box-number text-center text-muted mb-0">20</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card">
                                            <div class="card-header bg-secondary">
                                                <h3 class="card-title">Recent Progress - Jobsheet</h3>
                                            </div>
                                            <!-- /.card-header -->
                                            <div class="card-body p-0">
                                                <table class="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 10px">#No.</th>
                                                            <th>Date</th>
                                                            <th>Workdone</th>
                                                            <th>Open By</th>
                                                            <th>Remarks</th>
                                                            <th>Details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody *ngIf="jss !== 'No Data';else hide">
                                                        <tr *ngFor="let js of jss">
                                                            <td>{{js.js_id}}</td>
                                                            <td>{{date(js.js_created)}}</td>
                                                            <td>{{js.js_workdone}}</td>
                                                            <td>{{js.opener}}</td>
                                                            <td>{{js.js_remarks}}</td>
                                                            <td>View</td>
                                                        </tr>

                                                    </tbody>
                                                    <ng-template #hide>
                                                        <tbody>
                                                            <tr>

                                                                <td><span class="badge bg-danger">No Data%</span></td>
                                                            </tr>

                                                        </tbody>
                                                    </ng-template>
                                                </table>
                                            </div>
                                            <!-- /.card-body -->
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!---->

                        </div>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
        </div>
    </div>
</section>